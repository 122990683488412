<template>
    <div>
        <el-card>
            <el-page-header :content="title" style="margin-bottom: 30px" @back="goBack" />
            <nav class="input" style="width: auto">
                <label style="margin-right: 10px;">产品数据来源：</label>
                <el-radio-group v-model="source" @change="changeSource">
                    <el-radio label="0">点点数据接口</el-radio>
                    <el-radio label="1">自行添加</el-radio>
                </el-radio-group>
            </nav>
            <nav class="input" style="width: auto">
                <el-button type="primary" size="small" :disabled="source == 0" @click="addTheGoods()"
                    >添加产品</el-button
                >
            </nav>
        </el-card>

        <el-card style="margin-top: 10px">
            <el-table :data="list" style="width: 100%" border stripe :header-cell-style="rowClass">
                <el-table-column prop="id" width="60" label="编号" />
                <el-table-column prop="userName" label="logo">
                    <template slot-scope="{ row }">
                        <p-image :src.sync="baseurl + yu + row.logo"></p-image>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="名称" />
                <el-table-column prop="countryName" label="国家"></el-table-column>
                <el-table-column prop="marketName" label="商城"></el-table-column>
                <el-table-column prop="createTime" label="创建时间"> </el-table-column>
<!--                <el-table-column prop="app" label="关联APP">-->
<!--                    <template slot-scope="{ row }">-->
<!--                        <div>-->
<!--                            <el-button-->
<!--                                icon="el-icon-plus"-->
<!--                                v-if="row.joinProduct.length == 0"-->
<!--                                @click="addTheGoods(row)"-->
<!--                                type="text"-->
<!--                                >添加</el-button-->
<!--                            >-->
<!--                            <template v-for="item in row.joinProduct">-->
<!--                                <el-tag @close="onClose(row)" size="small" closable>{{ item.name }}</el-tag>-->
<!--                                <br /><span class="word-tip" style="font-size: 12px;"-->
<!--                                    >({{ item.marketName }})</span-->
<!--                                >-->
<!--                            </template>-->
<!--                        </div>-->
<!--                    </template>-->
<!--                </el-table-column>-->

                <el-table-column label="操作">
                    <template slot-scope="{ row }">
                        <!-- <el-button
                            :disabled="!!row.isSend"
                            type="text"
                            icon="el-icon-s-promotion"
                            size="mini"
                            style="color: skyblue"
                            @click="onEdit(row)"
                            >编辑</el-button
                        > -->
                        <el-button type="text" icon="el-icon-camera" size="mini" class="green" @click="onDel(row)"
                            >删除</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                v-if="total"
                :current-page="search.page"
                background
                :page-sizes="[3, 5, 10, 15]"
                :page-size="search.pageSize"
                layout="total, prev, pager, next"
                :total="total"
                @current-change="handleCurrentChange"
            />
        </el-card>
        <popup ref="pop" title="输入快递单号" :id="6" @getlist="getlist"></popup>
        <popup ref="pop_7" title="发货地址" :id="7" @getlist="getlist"></popup>
        <Drawers ref="drawer" @sub="submit" :title="editId ? '关联产品' : '添加产品'" @getlist="getlist">
            <template #top="{ row }">
                <el-col :span="10" style="margin-bottom: 10px">
                    <el-input placeholder="输入企业全称" v-model="keyword" class="input-with-select">
                        <el-button
                            slot="append"
                            icon="el-icon-search"
                            @click="searchFirms"
                            @change="searchFirms"
                        ></el-button>
                    </el-input>
                </el-col>
            </template>
        </Drawers>
    </div>
</template>

<script>
import list from "/src/mixin/list.js";
import popup from "@/components/popup.vue";
export default {
    mixins: [list],
    components: { popup },
    provide() {
        return {
            fthis: this,
        };
    },
    data() {
        return {
            search: {
                keyword: "",
                page: 1,
                pageSize: 10,
            },
            keyword: "",
            form: {},
            baseurl: this.$store.state.env.cos,
            yu: "/" + this.$store.state.env.domain,
            source: "0",
            editId: 0,
        };
    },
    methods: {
        async onClose(row) {
            // console.log(row);
            var { data: res } = await this.$http.post(
                "/admin/EnterpriseProduct/addJoinProduct",
                {
                    id: row.id,
                    joinProduct: JSON.stringify([]),
                },
                {
                    timeout: 300000,
                }
            );
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.getlist();
            }
            // console.log(row);
        },
        async getlist(val) {
            if (!this.$route.query.id) {
                return;
            }
            const form = val || this.search;

            let { data: res } = await this.$http.get(
                "/admin/EnterpriseProduct/getList?enterpriseId=" + this.$route.query.id
            );
            this.title = res.data.enterprise.name;
            this.list = res.data.list;
            this.total = res.data.totalCount;
            //   console.log(res);
        },
        async searchFirms() {
            this.$refs.drawer.loading = true;
            const { data } = await this.$http.get("/admin/EnterpriseProduct/search", {
                params: { keyword: this.keyword },
            });
            data.data.forEach((item) => {
                item.countryId = item.country[0]?.countryId || "";
            });
            this.$refs.drawer.loading = false;
            this.$refs.drawer.list = data.data;
        },
        async seeTheLink(row) {
            var { data: res } = await this.$http.get("/admin/BonusMall/getAddress", {
                params: { orderId: row.id },
            });
            //   console.log(res);
            this.$refs.pop_7.form = res.data;
            this.$refs.pop_7.dialogVisible = true;
        },
        collect() {
            this.$refs.drawer.init(9);
        },
        handleCurrentChange(e) {
            this.search.page = e;
            this.form.page = e;
            this.getlist(this.form);
        },
        async shelves(row) {
            this.$refs.pop.dialogVisible = true;
            this.$refs.pop.form = {
                ...row,
            };
        },
        onEdit(row) {
            this.$router.push("/enterprise_products_edit?id=" + row.id);
        },
        onDel(row) {
            this.$confirm("此操作将永久删除, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.$http.post("/admin/EnterpriseProduct/del", { id: row.id }).then(({ data: res }) => {
                        if (res.errorCode == 200) {
                            this.$message.success(res.message);
                            if (this.total % 10 == 1) {
                                this.querifo.page--;
                            }
                            this.getlist();
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },
        async getById() {
            let { data: res } = await this.$http.get("/admin/Enterprise/getById", {
                params: { id: this.$route.query.id },
            });
            this.source = String(res.data.productSource);
        },
        async changeSource() {
            try {
                let { data: res } = await this.$http.post("/admin/EnterpriseProduct/setProductSource", {
                    productId: this.$route.query.id,
                    productSource: +this.source,
                });
                if (res.errorCode == 200) {
                    this.$message.success(res.message);
                }
            } catch (error) {}
        },
        async addTheGoods(row) {
            if (row) {
                this.editId = row.id;
                this.$refs.drawer.editId = row.id;
            } else {
                this.editId = 0;
                this.$refs.drawer.editId = 0;
            }
            this.$refs.drawer.init(9);
        },
        subimt() {
            this.form = { ...this.search };
            this.getlist("/admin/EnterpriseProduct/search?keyword=");
        },
    },
    created() {
        this.getlist();
        this.form = { ...this.search };
        this.getById();
    },
};
</script>

<style lang="less" scoped></style>
